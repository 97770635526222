<template>
    <div
        v-for="(obj, idx) in dataRange"
        :key="idx"
    >
        <label :class="`${error != '' ? 'invalid' : ''}`">
            <input
                v-model="localValue"
                class="form-check-input"
                type="radio"
                :name="group.toString()"
                :value="obj.value"
            >
            {{ obj.name }}
        </label>
    </div>
</template>

<script>
export default {
    props: {
        range: {
            type: Array(Object),
            default: () => [],
            required: true
        },

        group: {
            type: [String, Number],
            default: () => btoa(crypto.getRandomValues(new Uint8Array(30))).slice(0, 30)
        },

        modelValue: {
            type: [String, Number],
            default: () => ''
        },

        error: {
            type: String,
            default: () => ''
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            updateFunc: this.updateMethod,

            dataRange: this.range,

            localValue: this.modelValue
        }
    },

    watch: {
        localValue(newVal) {
            this.$emit('update:modelValue', newVal);
        },

        modelValue(newVal) {
            this.localValue = newVal;
        }   
    }
}
</script>

<style lang="scss" scoped>
input[type='radio'] {
    border-radius: 50%;
    appearance: none;
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
}

input[type="radio"]:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.invalid {
    color: #dc3545;
    
    input[type="radio"] {
        border-color: #dc3545;
    }
}
</style>