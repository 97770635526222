<template>
    <loading-layer :is-loading="buffer.isLoading" />

    <form
        :loading="buffer.isLoading"
        @submit="checkForm"
    >
        <div class="form-header">
            <p class="h6 font-weight-bold">
                TODO: {{ __('MENUBAR.WELFARE') }}
            </p>
            <input
                v-if="buffer.loadingErrorMessage == ''"
                class="btn-wide btn-primary ml-auto"
                type="submit"
                :value="__('BUTTONS.UPDATE')"
            >
        </div>
        <p
            v-if="buffer.formError.hasErrors"
            class="text-danger text-right"
        >
            There are errors in the form !!
        </p>

        <div
            v-if="buffer.errorMessage != ''"
            class="alert alert-danger"
            role="alert"
        >
            {{ buffer.errorMessage }}
        </div>

        <div
            v-if="buffer.successMessage != ''"
            class="alert alert-success"
            role="alert"
        >
            {{ buffer.successMessage }}
        </div>

        <year-selector
            v-model="buffer.selectedYear"
            @year-updated="updateYear()"
        />

        <div
            v-if="buffer.loadingErrorMessage != ''"
            class="alert alert-danger"
            role="alert"
        >
            {{ buffer.loadingErrorMessage }}
        </div>

        <div v-if="buffer.loadingErrorMessage == '' && !buffer.isLoading && Object.keys(buffer.dropdownFacilitiesAndSpecies).length > 0">
            <p class="h5 mt-4">
                Facility:
            </p>
            <select
                v-model="buffer.selectedFacility"
                :class="`${Object.values(buffer.dropdownOptionsFacilityOk).includes(false) ? 'is-invalid' : ''} form-control`"
            >
                <option
                    v-for="fac in Object.keys(buffer.dropdownFacilitiesAndSpecies)"
                    :key="fac"
                    :value="fac"
                    :ok="buffer.dropdownOptionsFacilityOk[fac]"
                >
                    {{ fac }}
                </option>
            </select>
            <div class="invalid-feedback">
                Fill the form for all facilities
            </div>



            <p class="h5 mt-4">
                Specie:
            </p>
            <select
                v-model="buffer.selectedSpecie"
                :class="`${Object.values(buffer.dropdownOptionsSpecieOk[buffer.selectedFacility]).includes(false) ? 'is-invalid' : ''} form-control`"
            >
                <option
                    v-for="specieId in buffer.dropdownFacilitiesAndSpecies[buffer.selectedFacility]"
                    :key="specieId"
                    :value="specieId"
                    :ok="buffer.dropdownOptionsSpecieOk[buffer.selectedFacility][specieId]"
                >
                    {{ getSpecieName(specieId) }}
                </option>
            </select>
            <div class="invalid-feedback">
                Fill the form for all species
            </div>


            <table
                :loading="buffer.isLoading"
                class="table my-4"
            >
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            {{ __('WELFARE.QUESTION') }}
                        </th>
                    </tr>
                </thead>
                <tbody
                    v-for="section in buffer.kpiSections"
                    :key="section.title"
                >
                    <th colspan="2">
                        <span class="h6">
                            <b>> {{ section.title }}</b>
                        </span>
                    </th>
                    <tr
                        v-for="kpiId in section.kpiIds"
                        :key="kpiId"
                    >   
                        <td>
                            {{ buffer.welfareKpis[kpiId].name }}:
                            <div class="mt-2 ml-4">
                                <radio-list
                                    v-model="formData[buffer.selectedFacility][buffer.selectedSpecie][kpiId].value"
                                    :error="formData[buffer.selectedFacility][buffer.selectedSpecie][kpiId].error"
                                    :group="kpiId"
                                    :range="buffer.dropdownRanges[kpiId]"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>
</template>

<script>
import WELFARE_SECTIONS from '../../db/welfare/db-welfare_sections.json';
import WELFARE_ANSWERS from '../../db/welfare/db-welfare_answers.json';

import YearSelector from '../../components/year_selector.vue';
import LoadingLayer from '../../components/loading_layer.vue';
import RadioList from '../../components/radio_list.vue';

import Constants from '../../Constants';
import __ from '../../utils/translator.js';

import { getKpisFromType, getSpecieName } from '../../db/db-utils.js';
import { isInt } from '../../utils/form-utils.js';

import API from '../../utils/api-utils.js';

const welfareKpis = {};
getKpisFromType(Constants.KPI_WELFARE_ID).forEach(kpi => {
    welfareKpis[kpi.kpiId] = kpi;
});

const dropdownRanges = {};
WELFARE_ANSWERS.forEach(answer => {
    dropdownRanges[answer.kpiId] = answer.answers;
});

export default {

    components: {
        YearSelector,
        LoadingLayer,
        RadioList
    },
    inject: ['GLOBAL_ACTIVITIES'],

    data() {
        return {
            buffer: {
                selectedYear: Constants.MAX_KPIDATE_VALUE,
                selectedFacility: '',
                selectedSpecie: 0,

                dropdownFacilitiesAndSpecies: {},
                dropdownRanges,

                dropdownOptionsFacilityOk: {},
                dropdownOptionsSpecieOk: {},

                welfareKpis,
                kpiSections: WELFARE_SECTIONS,
                kpisConfig: {}, //kpi index contain config of thay kpi: {<kpiId>: {config} }

                yearRange: Array.from(
                    Array(
                        Constants.MAX_KPIDATE_VALUE - (Constants.MIN_KPIDATE_VALUE-1)).keys()
                    ).map(e => e + Constants.MIN_KPIDATE_VALUE),

                isLoading: true,
                errorMessage: '',
                successMessage: '',
                loadingErrorMessage: '',

                formError: {
                    hasErrors: false
                }
            },

            /**
             * Hierarchy:
             * Form data -> facility -> specie -> kpi -> { value, error }
             */
            formData: {}
        }
    },

    watch: {
        'buffer.selectedFacility'(newVal) {
            if (this.buffer.dropdownFacilitiesAndSpecies[newVal].indexOf(this.buffer.selectedSpecie) == -1) {
                this.buffer.selectedSpecie = this.buffer.dropdownFacilitiesAndSpecies[newVal][0];
            }
        }
    },

    //First of all, fetch API for facilities, and if exists fetch data.
    created() {
        const promises = [];
        promises.push(this.fetchFacilities());
        promises.push(this.setKPIsConfiguration());
        Promise.all(promises)
            .then(() => this.resetFormStructure())
            .finally(() => this.updateYear());
    },

    methods: {
        updateYear() {
            this.resetMessages();
            this.resetFormStructure();

            this.buffer.isLoading = true;
            const year = this.buffer.selectedYear;

            API.read.kpi.welfare(year)
                .then(response => {
                    if (response.ok) {
                        this.setDataIntoForm(response.data);
                    } else {
                        this.buffer.loadingErrorMessage = response.error.message;
                    }
                })
                .catch(() => this.buffer.loadingErrorMessage = 'Network error, try again later.')
                .finally(() => this.buffer.isLoading = false);
        },

        setDataIntoForm(apiData) {
            // this.resetFormStructure();

            apiData.forEach(kpi => {
                /**
                 * Check existance of indexes in the form,
                 * facility / specie may be removed
                 */
                if (kpi.facility in this.formData &&
                    kpi.specieId in this.formData[kpi.facility] &&
                    kpi.kpiId in this.formData[kpi.facility][kpi.specieId])
                        this.formData[kpi.facility][kpi.specieId][kpi.kpiId].value = kpi.value;
            });
        },

        resetMessages() {
            this.buffer.errorMessage = '';
            this.buffer.successMessage = '';
            this.buffer.loadingErrorMessage = '';
            this.buffer.formError.hasErrors = false;
        },

        checkForm(ev) {
            ev.preventDefault();
            
            this.resetMessages();
            
            let ok = true;

            const data = [];
            Object.entries(this.formData).forEach(([facility, species]) => {
                this.buffer.dropdownOptionsFacilityOk[facility] = true;

                Object.entries(species).forEach(([specieId, kpis]) => {
                    this.buffer.dropdownOptionsSpecieOk[facility][specieId] = true;

                    Object.entries(kpis).forEach(([kpiId, kpiObj]) => {
                        // first of all, reset errors
                        kpiObj.error = '';

                        const value = kpiObj.value;
                        const currentConfig = this.buffer.kpisConfig[kpiId];

                        const setDropdownErr = () => {
                            this.buffer.dropdownOptionsFacilityOk[facility] = false;
                            this.buffer.dropdownOptionsSpecieOk[facility][specieId] = false;
                        }
                        
                        //Validate form
                        if (value === '') {
                            kpiObj.error = 'Empty';
                            ok = false;
                            setDropdownErr();
                            return;
                        }

                        if (value > currentConfig.max || value < currentConfig.min) {
                            kpiObj.error = `Value must be between ${currentConfig.min} and ${currentConfig.max}`;
                            ok = false;
                            setDropdownErr();
                            return;
                        }

                        //type check
                        if (currentConfig.type == 'int' && !isInt(value)) {
                            kpiObj.error = `Value must be integer`;
                            ok = false;
                            setDropdownErr();
                            return;   
                        }

                        if (currentConfig.type == 'float' && Number(value) !== value) {
                            kpiObj.error = `Value must be numeric`;
                            ok = false;
                            setDropdownErr();
                            return;   
                        }

                        //Store form data ready to send
                        data.push({
                            facility,
                            kpiId: Number(kpiId),
                            value,
                            specieId: Number(specieId),
                            activityId: null,
                            year: this.buffer.selectedYear,
                            typeId: Constants.KPI_WELFARE_ID
                        });
                    });
                });
            });

            if (!ok) {
                this.buffer.formError.hasErrors = true;
                return;
            }

            this.buffer.isLoading = true;
            API.update.kpi(data)
                .then(response => {
                    if (response.ok) {
                        this.buffer.successMessage = 'Kpis successfully updated';
                    } else {
                        this.buffer.errorMessage = response.error.message;
                    }
                })
                .catch(() => this.buffer.loadingErrorMessage = 'Network error, try again later.')
                .finally(() => this.buffer.isLoading = false);
        },

        async fetchFacilities() {
            await API.read.facility()
                .then(response => {
                    if (response.ok) {
                        this.setFacilitiesDropdown(response.data);
                    } else {
                        this.buffer.loadingErrorMessage = response.error.message;
                    }
                })
                .catch(() => this.buffer.loadingErrorMessage = 'Network error, try again later.');
        },

        /**
         * Builds dropdown for the form as an object of:
         * dropdownFacilitiesAndSpecies[facName] = [specieIds of this fac]
         */
        setFacilitiesDropdown(rawFacilities) {
            const dropdown = this.buffer.dropdownFacilitiesAndSpecies; //reference
            const dropdownOptionsFacility = this.buffer.dropdownOptionsFacilityOk; //reference
            const dropdownOptionsSpecie = this.buffer.dropdownOptionsSpecieOk; //reference

            rawFacilities.forEach((fac, i) => {
                let species = fac.activities.map(a => a.specieId);
                species = species.filter((s, i, arr) => arr.indexOf(s) == i);

                if (i == 0) { //set default selected
                    this.buffer.selectedFacility = fac.name;
                    this.buffer.selectedSpecie = species[0];
                }

                dropdown[fac.name] = species;

                dropdownOptionsFacility[fac.name] = true;
                dropdownOptionsSpecie[fac.name] = {};
                species.forEach(specie => {
                    dropdownOptionsSpecie[fac.name][specie] = true;
                });
            });
        },

        /**
         * Builds data structure for storaging form data
         * 
         * An object hirarchy is created for storaging indexed information for each kpi.
         * Data is accessed in this way:
         * formData[<facilityName>][<specieId>][<kpiId>].value
         */
        resetFormStructure() {
            Object.entries(this.buffer.dropdownFacilitiesAndSpecies).forEach(([fac, specieIds]) => {
                this.formData[fac] = {};
                specieIds.forEach(specieId => {
                    this.formData[fac][specieId] = {};

                    Object.values(this.buffer.welfareKpis).forEach(kpi => {
                        this.formData[fac][specieId][kpi.kpiId] = {
                            value: -1,
                            error: ''
                        };
                    })
                });
            });
        },

        /**
         * Configuration of each kpi in form of:
         * <kpiId>: {
         *     max: 0,
         *     min: 0,
         *     ...
         * }
         */
        async setKPIsConfiguration() {
            Object.values(this.buffer.welfareKpis).forEach(kpi => {
                this.buffer.kpisConfig[kpi.kpiId] = kpi.config;
            });
        },

        getSpecieName,
        __ //TRANSLATOR
    }
}
</script>

<style lang="scss" scoped>
@import '../../_custom.scss';

option[ok="false"] {
    background-color: map-get($theme-colors, "danger");
}
</style>